import React from "react"
import { useState } from "react"
import FilterButton from "../common/filter/FilterButton"
import SearchBox from "../common/searchbox/SearchBox"
import "./banner.scss"
import { useMediaQuery } from "react-responsive"
import { navigate } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { isBrowser } from "../../context/utils"
import Logo from "../../assets/aia-logo"
import { Link as GatsbyLink } from "gatsby"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import CourseRegistrationForm from "../product-detail/CourseRegistration/courseRegistrationForm"

const TitleBannerEnquiry = (props) => {
  const {
    id,
    title,
    description,
    handleFilterClick,
    isFilterOpen,
    withoutFilter,
    selfCheckout,
    withOnlyDescription,
    hasPlainBanner,
    netFee,
    coverPhoto,
    scroll,
    courseRuns,
  } = props

  const isMobile = useMediaQuery({
    query: "(max-width: 565px)",
  })

  const [isSuccess, setIsSuccess] = useState(false)
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const handleRegisterClick = () => {
    let url =
      "mailto:training@aia.edu.sg?subject=Request for Course Quotation&amp;body=Hello Ace, D%0A Course Title: test %0D%0A Name: D%0A Email: D%0A Contact Number:"
    if (!isBrowser()) return
    window.open(url, "_blank")
  }

  const bannerImage = useStaticQuery(
    graphql`
      query {
        background1: file(
          relativePath: { eq: "innerpage-bg.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        background2: file(
          relativePath: { eq: "contact-bg.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const BannerBGImage1 =
    bannerImage.background1.childImageSharp.fluid
  const BannerBGImage2 =
    bannerImage.background2.childImageSharp.fluid

  return (
    <>
      <BackgroundImage
        className="innerPageBanner"
        fluid={
          hasPlainBanner ? BannerBGImage2 : BannerBGImage1
        }
      >
        <div className="container">
          <div className="innerPageContent">
            <h1 className="innerPageTitle">{title}</h1>
            <p className="sliderDescription">
              {description}
            </p>
            <div className="innerPageContentForm">
              <div className="actionBtns">
                <button
                  href={``}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btnRegister"
                  onClick={() => handleRegisterClick()}
                >
                  Enquire Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            <GatsbyLink to="/">
              <Logo />
            </GatsbyLink>
          </ModalHeader>
          <ModalBody>
            <CourseRegistrationForm
              price={netFee}
              selectedCourse={title}
              coverPhoto={coverPhoto}
            />
          </ModalBody>
        </Modal>
      </div>
    </>
  )
}

export default TitleBannerEnquiry
