import styled from "styled-components"

export const ReviewsContainer = styled.div`
  margin-bottom: 45px;
`
export const ReviewsListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .testimonial_content 
      margin-top: 10px;

`
