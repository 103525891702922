import React from "react"
import Testimony from "../../home/testimonials/testimony/Testimony"
import miscl from "../../../data/miscl.json"
import {
  ReviewsContainer,
  ReviewsListWrapper,
} from "./styles"
import { Title } from "../itemDescription/styles"

const Reviews = (props) => {
  const { isCertificate } = props
  const reviews = miscl.testimonial

  return (
    <ReviewsContainer>
      <Title>
        {isCertificate ? "Certification " : "Course"}{" "}
        Reviews
      </Title>

      <ReviewsListWrapper>
        {reviews?.map((review, i) => (
          <Testimony
            key={i}
            review={review}
            fromDetailPage
          />
        ))}
      </ReviewsListWrapper>
    </ReviewsContainer>
  )
}

export default Reviews
