import { isBrowser } from "./utils"

const MailTo = () => {
  let url =
    "mailto:training@aia.edu.sg?subject=Request for Course Quotation&amp;body=Hello Ace, D%0A Course Title: test %0D%0A Name: D%0A Email: D%0A Contact Number:"
  if (!isBrowser()) return
  window.open(url, "_blank")
}

export default MailTo
