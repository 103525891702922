import React, { useContext } from "react"
import { Container, Row, Col } from "reactstrap"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import TitleBanner from "../components/titleBanner/TitleBanner"
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"
import CardsList from "../components/common/cardsList/CardsList"
import Reviews from "../components/product-detail/itemReviews/Reviews"
import { GlobalStateContext } from "../context/GlobalContextProvider"

let ShortCourses = ({ data }) => {
  const { allContentfulTraining } = data
  const ShortCourses = allContentfulTraining.nodes
  return (
    <>
      <SEO
        title="Best IFC Training Course in Singapore | Ace Industrial Academy"
        description="Ace Industrial Academy is a Skillsfuture Singapore Approved Training Organisation. Learn more about CAD, BIM, and IFC here today."
      />

      <TitleBannerEnquiry
        title="Planner 5D Interactive Workshop"
        description="Start working on Planner 5D (PRO Version) to plan and visualize your home design"
        withOnlyDescription
        withoutFilter
      />

      <div className="container">
        <CardsList
          withoutFilter
          courses={ShortCourses}
        />
        {/* <div className="reviews-cover">
          <Reviews />
        </div> */}
      </div>
    </>
  )
}

export const ShortCoursesQuery = graphql`
  query allShortQuery {
    allContentfulTraining(
      filter: { category: { eq: "short-courses" } }
    ) {
      nodes {
        id
        title
        courseReference
        trainingMode
        updatedAt
        skillsFramework
        requirements
        lessons
        duration
        product {
          id
          name
          netFee
          price
        }
        metaDescription
        category
        slug
        coverPhoto {
          gatsbyImageData
        }
        courseRuns
      }
    }
  }
`

export default ShortCourses
